.containerGroup{
    width: 100%;
    height: 93%;
    display: flex;
}
.contEmptyGroup{
    max-width: 270px !important;
    min-width: 230px;
}
.contBodyGroup{
    display: flex;
    width: 100%;
}
.contTitleGroup{
    width: 96%;
    margin: 20px 0 10px 24px;
}
.containerForm{
    width: 70%;
    min-height: 250px;
    overflow: visible;
    filter: drop-shadow(0px 0px 2px rgba(1, 1, 2, 0.2)) !important;
    background-color: #F0F4F7;
    margin: auto;
    border-radius: 10px;
}
.contFormGroupReservation{
    padding: 20px;
    
}
.autoComplete{
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 20px !important;
    min-height: 40px !important;
    font-size: 14px;
    color: #243747;  
}
.btnGroupReserveForm{
    background-color: #243747 !important;
    color: #fff !important;
    width: 120px;
    border-radius: 20px !important;
    margin-right: 20px !important;
}
.btnGroupCancelForm{
    background-color: #fff !important;
    color: #243747 !important;
    width: 120px;
    border-radius: 20px !important;
}

@media screen and (max-width: 860px) and (min-width: 600px){
    .containerForm{
        width: 95%;
        min-height: 250px;
        overflow: visible;
        filter: drop-shadow(0px 0px 2px rgba(1, 1, 2, 0.2)) !important;
        background-color: #F0F4F7;
        margin: auto;
        border-radius: 10px;
    }
}
@media screen and (max-width: 599px) and (min-width: 150px){
    .contEmptyGroup{
        max-width: 0px !important;
        min-width: 0px;
    }
    .containerForm{
        width: 100%;
        min-height: 250px;
        overflow: visible;
        filter: drop-shadow(0px 0px 2px #F0F4F7) !important;
        background-color: #F0F4F7;
        margin: auto;
        border-radius: 10px;
    }
}
@media screen and (max-width: 300px) and (min-width: 150px){
    .btnGroupReserveForm{
        background-color: #243747 !important;
        color: #fff !important;
        width: 100% !important;
        border-radius: 20px !important;
        margin-right: 0px !important;
        margin-bottom: 15px !important;
    }
    .btnGroupCancelForm{
        background-color: #fff !important;
        color: #243747 !important;
        width: 100% !important;
        border-radius: 20px !important;
    }
}