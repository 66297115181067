
.dialogTitleAvailable{
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 10px !important;
}
.titleAssign{
    margin-top: 10px !important;
    text-align: center !important;
    justify-content: center !important;
    border-radius: 10px !important;
}
.dialogContentAssign{
    min-width: 500px !important;
    margin-top: 20px !important;
    padding: 0px 20px !important;
    margin: 0px;
    justify-content: center !important;
    text-align: center !important;
}
.gridButtom{
    justify-content: end !important;
    margin: 0 20px 5px 0 !important;
}
.btnCloseAvailability{
    background-color: #243747 !important;
    color: #fff !important;
    overflow: visible !important;
    filter: drop-shadow(0px 0px 2px rgba(0,2,9,.10)) !important;
    border-radius: 20px !important;
    width: 100px !important;
}

/* style accordion */
.accordionAvailable{
    overflow: visible !important;
    filter: drop-shadow(0px 0px 2px rgba(1, 1, 2, 0.2)) !important;
    border-left:  0px solid #243747;
    margin: 10px 0 !important;
    border-radius: 10px !important;
    width: 850px;
    pointer-events: none;
}
.column{
    flex-basis: 33%;
    margin-left: 10px !important;
    border-radius: 10px !important;
    text-align: start !important;
}
.secondaryHeading{
    font-size: 12px !important;
    color: #647d91 !important;
}

.calendar-container{
    width: 100%;
}

.reservation-modal-container{
    margin: 16rem auto;
    max-width: 48rem;
    padding: 1rem;
}

.empty-reservation-container{
    width: 100%;
    min-height: 4rem;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.textNoReservation {
    margin: 0;
    text-align: center;
    font-size: 1.2rem;
    color: #5f788d;
    font-family: system-ui;
}
.contIconBed{
    display: flex;
    width: 100% !important;
    justify-content: center;
    margin-top: 10px;
}
.contIconBedTwo{
    display: flex;
    width: 50px !important;
}
.styleIcon{
    font-size: 1.3rem !important;
    margin: 0 5px 0 0 !important;
    color: #5f788d !important;
}



.fullCalendar{
    background-color: #b3ff84 !important;
}
.fullCalendar2{
    background-color: #fb0303 !important;
}
.avContainerAvailability{
    width: 100%;
    box-sizing: border-box;
    margin-top: 20px;
    display: flex;
}
.avContainTitleText{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}
.avTitle{
    margin: 0 20px 0px 0;
    color: #243747;
    font-size: 17px;
    font-weight: 600;
    font-family: system-ui;
}
.avText{
    margin: 0;
    color: #243747;
    font-size: 17px;
    font-weight: 700;
    font-family: system-ui;
}

/* media query */
@media screen and (max-width: 800px) and (min-width: 100px){
    .avContainerAvailability{
        width: 100%;
        box-sizing: border-box;
        margin-top: 15px;
        display: block;
    }
    .avContainTitleText{
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }
}
@media screen and (max-width: 600px) and (min-width: 150px){
    .mod-res-det{
        text-align: center !important;
        font-size: 18px !important;
    }
    .tableContainer{
        display: flex !important;
    }
    .textNoReservation {
        font-size: 15px !important;
    }
    .avTitle{
        margin: 0 20px 0px 0;
        color: #243747;
        font-size: 15px;
        font-weight: 600;
        font-family: system-ui;
    }
    .avText{
        margin: 0;
        color: #243747;
        font-size: 15px;
        font-weight: 700;
        font-family: system-ui;
    }
}

@media screen and (max-width: 600px) and (min-width: 331px){
    .tableCellHead{
        display: flex !important;
        flex-direction: column !important;
    }
    .tableCellBody{
        display: flex !important;
        flex-direction: column !important;
        margin-top: 4px;
    }
}

@media screen and (max-width: 330px) and (min-width: 150px){
    .tableCellHead{
        display: block !important;
        flex-direction: column !important;
        font-size: 12px !important;
        height: 40px !important;
    }
    .tableCellBody{
        display: block !important;
        flex-direction: column !important;
        margin-top: 0px;
        font-size: 12px !important;
        height: 40px !important;
    }
}