.list-container{
  margin: 0;
  padding: 0 0 10px 15px;
}

.list-container p{
  font-size: 15px;
  margin: 0;
}

/* media query */
@media screen and (max-width: 660px) and (min-width: 600px){
  .cont-item-lis{
      margin-right: 10px !important;
  }
}

@media screen and (max-width: 460px) and (min-width: 150px){
  .cont-item-lis{
    margin-right: 10px !important;
  }
  .list-container p{
    font-size: 14px;
    margin: 0;
  }
}