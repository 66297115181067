.rootReserve{
    display: flex !important;
    width: 100% !important;
    height: 90% !important;
}
.containerBody{
    display: flex !important;
    width: 100% !important;
    margin-left: 230px !important;
}
.containerPrimaryReserve{
    display: flex !important;
    width: 95% !important;
    height: 100% !important;
    margin: auto auto !important;
}
.contSecondaryReserve{
    display: flex !important;
    width: 100% !important;
    min-height: 350px !important;
    background-color: #F0F4F7 !important;
    margin: auto auto !important;
    overflow: visible;
    padding: 15px 0px !important;
}
.containerFormKeepMind{
    width: 100% !important;
    height: 100%;
}
.titleKeepMind{
    margin: 0 0 20px 0 !important;
    padding: 0 0 0 10px !important;
    font-size: 15px !important;
}
.divKeepInMind{
    width: 100% !important;
    min-height: 150px !important;
    padding: 10px 0px !important;
    border-radius: 10px !important;
    overflow: visible;
    filter: drop-shadow(0px 0px 2px rgba(1, 1, 2, 0.2)) !important;
    background-color: #fff !important;
    margin-bottom: 30px !important;
}

.contFormReserve{
    width: 100% !important;
}
.inputForm{
    background: #ffffff !important;
    box-sizing: border-box !important;
    border-radius: 20px !important;
    font-size: 14;
    color: #243747;
}
.btnReserveForm{
    background-color: #243747 !important;
    color: #fff !important;
    margin-right: 10px !important;
    border-radius: 20px !important;
    width: 33%;
    height: 40px ;
}
.btnCancelReserve{
    background-color: #fff !important;
    color: #5f788d !important;
    border-radius: 20px !important;
    width: 33%;
    overflow: visible !important;
    filter: drop-shadow(0px 0px 2px rgba(1, 1, 2, 0.2)) !important;
    height: 40px ;
}
.menuItemSelelct{
    border-radius: 0px !important;
}
.menuItemSelelct:hover{
    border-left: 3px solid #243747 !important;
}
.listKeepMind{
    margin: 0;
    padding: 0 15px 0px 30px;
}
.titleNumberGuest{
    width: 100% !important;
    margin: 10px 10px 10px 20px;
    font-size: 12px;
    color: #243747;
}

/* media query */

@media screen and (max-width: 400px) and (min-width: 150px){
    .btnReserveForm{
        background-color: #243747 !important;
        color: #fff !important;
        margin: 0 10px 15px 0 !important;
        border-radius: 20px !important;
        width: 100%;
        height: 40px;
    }
    .btnCancelReserve{
        background-color: #fff !important;
        color: #5f788d !important;
        border-radius: 20px !important;
        width: 100%;
        overflow: visible !important;
        filter: drop-shadow(0px 0px 2px rgba(1, 1, 2, 0.2)) !important;
        height: 40px ;
    }
}

@media screen and (max-width: 1050px) and (min-width: 150px){
    .containerFormKeepMind{
        width: 100% !important;
        height: 100%;
    }
}

@media screen and (max-width: 600px) and (min-width: 150px){
    .containerBody{
        display: flex !important;
        width: 100% !important;
        margin-left: 0px !important;
    }
}