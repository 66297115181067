.dialogTitleCountry{
    margin: 0 !important;
    padding: 0 !important;
}
.titleCountry{
    display: flex;
    justify-content: center;
    padding: 15px;
}
.dialogContentCountry{
    width: 400px !important;
    min-height: 120px;
}
