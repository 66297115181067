.btnCancelMyReser{
    background-color: #fff !important;
    color: #5f788d !important;
    font-size: 11px !important;
    border-radius: 19px !important;
    width: 100px !important;
    pointer-events: auto !important;
    filter: drop-shadow(0px 0px 2px rgba(9,7,9,.10)) !important;
}
.containerMessageNoReservation{
    width: 100%;
    text-align: start;
}
.noReservations{
    font-size: 22px;
    color: #5f788d;
}
.contHeaderTableRes{
    display: flex;
    width: 100%;
}

@media screen and (max-width: 800px) and (min-width: 150px){
    .contHeaderTableRes{
        display: block;
        width: 100%;
    }
    .btnCancelMyReser{
        background-color: #fff !important;
        color: #5f788d !important;
        font-size: 11px !important;
        border-radius: 19px !important;
        width: 100% !important;
        pointer-events: auto !important;
        filter: drop-shadow(0px 0px 2px rgba(9,7,9,.10)) !important;
    }
}