.containerEmpty{
    display: flex !important;
    width: 100vw !important;
    height: 60vh !important;
}
.containerSecondaryEmpty{
    margin: auto auto !important;
    width: 50% !important;
    height: 300px !important;
}
.contBodyEmpty{
    display: block !important;
    width: 100% !important;
    justify-content: center !important;
    text-align: center !important;
}
.contTextEmpty{
    display: inline-block !important;
    width: 100% !important;
}
.iconEmpty{
    font-size: 150px !important;
    color: #243747 !important;
}
.textIsEmpty{
    text-align: center !important;
    color: #5f788d !important;
    margin: 30px!important;
    font-size: 30px !important;
}

/* media query */
@media screen and (max-width: 600px) and (min-width: 150px){
    .iconEmpty{
        font-size: 100px !important;
        color: #243747 !important;
    }
    .textIsEmpty{
        text-align: center !important;
        color: #5f788d !important;
        margin: 30px!important;
        font-size: 20px !important;
    }
}