.rootLocation{
    display: block !important;
    margin: 15px 0px 15px 232px;
}
.containerSearch{
    width: 100%;
    display: inline-block !important;
    margin: 30px 15px 30px 0;
}
.autoComplete{
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 20px !important;
    min-height: 40px !important;
    font-size: 14px;
    color: #243747;
   
}
.containerCard{
    display: inline-flex !important;
    width: 100% !important;
    height: 100% !important;
    margin-top: 20px;
    text-align: center;
}

@media screen and (max-width: 599px) and (min-width: 150px){
    .rootLocation{
        display: block !important;
        margin: 15px 0px 15px 15px;
    }
}

@media screen and (max-width: 1341px) and (min-width: 1200px){
    .divSearchLocation{
        max-width: 230px !important;
    }
    .containerSearchLocation{
        min-width: 280px !important;
    }
}