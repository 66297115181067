.appBar{
    height: 50px !important;
    background: #F0F4F7 !important;
}
.grow{
    flex-grow: 1;
}
.toolbar{
    display: flex;
    min-height: 50px !important;
    justify-content: space-between !important;
    background-color: #F0F4F7;
    overflow: visible;
    filter: drop-shadow(0px 0px 1px rgba(0,2,0,.22));
}
.drawer{
    width: 200px !important;
    background-color: #243747 !important;
    border-radius: 10px;
    height: 100%;
}
.textDrawer{
    color: #fff;
    padding: 0 !important;
    margin: 0 !important;
}
.textDrawerFocus{
    color: #5ce706;
    padding: 0 !important;
    margin: 0 !important;
}
.iconDrawer{
    color: #fff;
    margin: 5px 10px 5px 5px !important;
    padding: 0 !important;
}
.iconDrawerFocus{
    color: #5ce706;
    margin: 5px 10px 5px 5px !important;
    padding: 0 !important;
}
.listItem:hover{
    background: #2e475c !important;
}
.ListItemButton:active{
    background-color: #324a5e !important;
}
.tooltip{
    color: #5f788d !important;
}
.iconBtnNotification{
    height: 40px;
    width: 40px;
    margin-right: 15px !important;
}
.iconBtnNotification:hover{
    background-color: #dbeaf5 !important;
    border-color: #243239 !important;
    box-shadow: none !important;
}
.iconLogout{
    height: 40px;
    width: 40px;
}
.iconLogout:hover{
    background-color: #dbeaf5 !important;
    border-color: #243239 !important;
    box-shadow: none !important;
}
.profile{
    margin-right: 10px !important;
}
.profile:hover{
    background-color: #F0F4F7 !important;
    box-shadow: none !important;
}
.iconProfile{
    height: 25px !important;
    width: 25px !important;
    background: #fff
}
.imgTeam{
    width: 80px;
    text-align: start !important;
    /* margin: auto; */
}
.iconBtnMenu{
    height: 40px;
    width: 40px;
}
.iconAppbar{
    height: 25px;
    width: 25px;
    color: #243747
}
.iconAppbarColor{
    height: 25px;
    width: 25px;
    color: #5ce706
}

/* media query */

@media screen and (max-width: 400px) and (min-width: 150px){
    .iconBtnMenu{
        height: 30px;
        width: 30px;
    }
    .iconAppbar{
        height: 20px !important;
        width: 20px !important;
        color: #243747
    }
    .iconAppbarColor{
        height: 20px;
        width: 20px;
        color: #5ce706
    }
    .iconLogout{
        height: 30px;
        width: 30px;
    }
    .iconBtnNotification{
        height: 30px;
        width: 30px;
        margin-right: 10px !important;
    }
    .iconProfile{
        height: 20px !important;
        width: 20px !important;
        background: #fff
    }
}