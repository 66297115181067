.dialogTitleModalGallery{
    margin: 0 !important;
    padding: 0 !important;
}
.titleModalGallery{
    display: flex;
    justify-content: center;
    padding: 15px;
}
.dialogContentModalGallery{
    min-height: 120px;
}
.btnCloceModalUpdated{
    background-color: #243747 !important;
    border-radius: 20px !important;
    width: 100px !important;
}
.contBtnCloceModal{
    display: flex !important;
    text-align: center !important;
    justify-content: center !important;
}
.containMianModalUpdated{
    display: grid !important;
    grid-template-columns: 20% 20% 20% 20% 20%;
}
.contSecondaryModalUpdated{
    margin: 0px 0px 20px 0;
    display: flex;
    justify-content: center;
}
.divImgModalUpdate{
    width: 130px !important;
    height: 130px;
    border-radius: 5px;
    display: block;
}
.imgModalUpdated{
    width: 130px;
    height: 130px;
    border-radius: 5px;
    margin: auto;
}
.contIconsModalUpdated{
    width: 130px;
    height: 30px;
    display: flex !important;
    justify-content: space-around;
    position: relative;
    align-items: center;
    background-color: #000;
    opacity: 40%;
    margin: auto auto 0 auto !important;
    bottom: 34px;
    border-radius: 0 0 5px 5px;
}
.iconDeleteModalGallery{
    color: #fff !important;
    opacity: 100% !important;
    font-size: 18px !important;
    cursor: pointer;
}
.iconEditGallery:hover{
    background-color: rgb(255, 255, 255) !important;
    font-size: 20px !important;
    opacity: 100%;
    width: 26px;
    height: 26px;
}
.iconEditGallery:hover .iconDeleteModalGallery{
    color: #000000 !important;
    opacity: 100% !important;
}
.iconAddImage{
    position: absolute !important;
    top: 0.5rem;
    right: 1.5rem
}
.iconAddImageGallery{
    color: #243747 !important;
    font-size: 2rem !important;
}

/* media query */
@media screen and (max-width: 800px) and (min-width: 650px){
    .containMianModalUpdated{
        display: grid !important;
        grid-template-columns: 25% 25% 25% 25%;
    }
}

@media screen and (max-width: 649px) and (min-width: 520px){
    .containMianModalUpdated{
        display: grid !important;
        grid-template-columns: 33% 33% 33%;
    }
}

@media screen and (max-width: 519px) and (min-width: 400px){
    .containMianModalUpdated{
        display: grid !important;
        grid-template-columns: 50% 50%;
    }
}

@media screen and (max-width: 399px) and (min-width: 100px){
    .containMianModalUpdated{
        display: grid !important;
        grid-template-columns: 100%;
        margin-top: 40px;
    }
    .iconAddImage{
        position: absolute !important;
        top: 2rem;
        right: 50% !important;
        left: 50% !important;
    }
}