.dialogTitleLock{
    margin: 0 !important;
    padding: 0 !important;
}
.titleLock{
    display: flex;
    justify-content: center;
    padding: 15px;
}
.dialogContentLock{
    width: 450px !important;
    min-height: 120px;
}

/* media query */
@media screen and (max-width: 460px) and (min-width: 320px){
    .dialogContentLock{
        width: 200px !important;
        min-height: 120px;
    }
}

@media screen and (max-width: 319px) and (min-width: 150px){
    .dialogContentLock{
        width: 120px !important;
        min-height: 120px;
    }
}