.contSearchReservation{
    width: 100% !important;
    margin: 0 0 30px 0 !important;
}
.divBtnTabReser{
    width: 100% !important;
    padding: 10px 0 0 0 ;
    justify-content: end;
}
.btnAccordionReser{
    background-color:#fff !important;
    color: #647d91 !important;
    font-size: 11px !important;
    border-radius: 19px !important;
    width: 100px !important;
    height: 35px !important;
    overflow: visible;
    filter: drop-shadow(0px 0px 2px rgba(1,7,0,.17));
    margin-left: 10px !important;
}
.columnReservation{
    flex-basis: 19% !important;
    margin-left: 10px !important;
    text-align: start;
    margin-top: 10px;
}
.containerDetail{
    display: flex;
    width: 100%;
}
.containerDetailTitle{
    display: flex !important;
    width: 100%;
}
.containGuestBook{
    margin: 15px 0 0 10px;
}
.containDescription{
    margin-top: 15px !important;
    margin: 15px 0 15px 10px;
}
.columnGuest{
    flex-basis: 19%;
    text-align: start;
    margin: 0 10px 10px 0;
}
.gusetEqualZero{
    font-size: 14px;
    width: 100%;
    text-align: start;
}
.headingGuest{
    font-size: 13px !important;
    color: #647d91 !important;
}
.dividerGuest{
    width: 100% !important;
    margin: 0 0 8px 0 !important;
    color: #647d91 !important;
}

/* media query */
@media screen and (max-width: 630px) and (min-width: 380px){
    .btnAccordionReser{
        background-color:#fff !important;
        color: #647d91 !important;
        font-size: 11px !important;
        border-radius: 19px !important;
        width: 90px !important;
        height: 35px !important;
        overflow: visible;
        filter: drop-shadow(0px 0px 2px rgba(1,7,0,.17));
        margin-left: 10px !important;
    }
}

@media screen and (max-width: 380px) and (min-width: 150px){
    .btnAccordionReser{
        background-color:#fff !important;
        color: #647d91 !important;
        font-size: 11px !important;
        border-radius: 19px !important;
        width: 100% !important;
        height: 35px !important;
        overflow: visible;
        filter: drop-shadow(0px 0px 2px rgba(1,7,0,.17));
        margin-top: 10px !important;
    }
    .divBtnTabReser{
        width: 100% !important;
        padding: 0;
        justify-content: end;
    }
}

@media screen and (max-width: 800px) and (min-width: 150px){
    .columnReservation{
        display: flex;
        flex-basis: 30% !important;
        margin-left: 10px !important;
        text-align: start;
        margin-bottom: 5px;
    }
    .containerDetail{
        display: block !important;
        width: 100%;
    }
    .containerDetailTitle{
        display: block !important;
        width: 100%;
    }
}

@media screen and (max-width: 1280px) and (min-width: 801px){
    .columnReservation{
        display: block;
        flex-basis: 18% !important;
        margin-left: 10px !important;
        text-align: start;
        margin-bottom: 5px;
    }
}

@media screen and (max-width: 900px) and (min-width: 100px){
    .containDescription{
        margin-top: 15px !important;
        margin: 15px 0 5px 10px;
    }
}

@media screen and (max-width: 1280px) and (min-width: 880px){
    .columnGuest{
        flex-basis: 23%;
        text-align: start;
        margin: 0 10px 10px 0;
    }
}

@media screen and (max-width: 879px) and (min-width: 100px){
    .columnGuest{
        flex-basis: 100%;
        text-align: start;
        margin: 0 10px 10px 0;
    }
}
