.App {
  background: #F0F4F7;
  flex: 1;
}
.rootGeneral{
  display: block !important;
  margin: 15px 15px 15px 232px;
}
.titleMain{
  font-weight: bold;
  font-size: 20px;
  color: #243747;
  margin: 0 !important;
  padding: 0 !important;
  text-transform: uppercase;
}

/* botones filtros */

.btnCleanFilter{
  background: #fff !important;
  width: 126px !important;
  color: #5f788d !important;
  overflow: visible !important; 
  filter: drop-shadow(0px 0px 1px rgba(9,7,9,.10)) !important;
  border-radius: 30px !important;
}
.iconButonFormFilter{
  margin-right: 5px !important;
}
.btnSearchFilter{
  background: #243747 !important;
  width: 126px !important;
  color: #fff !important;
  margin-right: 10px !important;
  overflow: visible !important; 
  filter: drop-shadow(0px 0px 1px rgba(9,7,9,.10)) !important;
  border-radius: 30px !important;
}

/* accordion */
.detailTable{
  align-items: start !important;
}
.column{
  flex-basis: 30%;
  margin-left: 10px !important;
}
.secondaryHeading{
  font-size: 13px !important;
  color: #647d91 !important;
}

/* inputs */
.inputForm{
  background: #ffffff !important;
  box-sizing: border-box !important;
  border-radius: 20px !important;
  font-size: 14;
  color: #243747;
}
.inputFormDisabled{
  background: #ebebeb !important;
  box-sizing: border-box !important;
  border-radius: 20px !important;
  font-size: 14;
}
.menuItemSelelct{
  border-radius: 0px !important;
  text-align: start !important;
}
.menuItemSelelct:hover{
  border-left: 3px solid #243747 !important;
}

/* botones y div modal */
.btnSubmitModal{
  background-color: #fff !important;
  color: #5f788d !important;
  width: 130px;
  border-radius: 20px !important;
  filter: drop-shadow(0px 0px 2px rgba(9,7,9,.10)) !important;
}
.contBtnModals{
  display: flex;
  justify-content: space-around;
}


/* media query */

@media screen and (max-width: 300px) and (min-width: 150px){
  .iconButonFormFilter{
    display: none !important;
  }
  .btnCleanFilter{
    background: #fff !important;
    width: 110px !important;
    color: #5f788d !important;
    overflow: visible !important; 
    filter: drop-shadow(0px 0px 1px rgba(9,7,9,.10)) !important;
    border-radius: 30px !important;
  }
  .btnSearchFilter{
    background: #243747 !important;
    width: 110px !important;
    color: #fff !important;
    margin-right: 15px !important;
    overflow: visible !important; 
    filter: drop-shadow(0px 0px 1px rgba(9,7,9,.10)) !important;
    border-radius: 30px !important;
  }
}

/* media query btn filter */
@media screen and (max-width: 261px) and (min-width: 150px){
  .btnCleanFilter{
    background: #fff !important;
    width: 100% !important;
    color: #5f788d !important;
    overflow: visible !important; 
    filter: drop-shadow(0px 0px 1px rgba(9,7,9,.10)) !important;
    border-radius: 30px !important;
    margin-top: 15px !important;
  }
  .btnSearchFilter{
    background: #243747 !important;
    width: 100% !important;
    color: #fff !important;
    margin-right: 15px !important;
    overflow: visible !important; 
    filter: drop-shadow(0px 0px 1px rgba(9,7,9,.10)) !important;
    border-radius: 30px !important;
  }
}

@media screen and (max-width: 599px) and (min-width: 150px){
  .rootGeneral{
    display: block !important;
    margin: 15px 15px 15px 15px;
  }
  .titleMain{
    font-weight: bold;
    font-size: 18px;
    color: #243747;
    margin: 0 !important;
    padding: 0 !important;
    text-transform: uppercase;
  }
}

@media screen and (max-width: 800px) and (min-width: 150px){
  .column{
    display: flex;
    flex-basis: 40% !important;
    margin-left: 10px !important;
    margin-bottom: 5px;
  }
  .secondaryHeading{
    font-size: 12px !important;
    color: #647d91 !important;
    padding: 2px 0 0 10px;
    text-align: start !important;
  }
}

@media screen and (max-width: 461px) and (min-width: 150px){
  .btnSubmitModal{
    background-color: #fff !important;
    color: #5f788d !important;
    width: 100%;
    border-radius: 20px !important;
    filter: drop-shadow(0px 0px 2px rgba(9,7,9,.10)) !important;
    margin-bottom: 10px !important;
  }
  .contBtnModals{
    display: block !important;
    justify-content: space-around;
  }
}