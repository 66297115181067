
.contBtnLogout{
    text-align: center !important;
    justify-content: center;
}
.conchangePassword{
    text-align: center !important;
    justify-content: center;
    margin: 10px 0 10px 0 !important;
}
.contMainLogout{
    padding: 15px;
}
.menuItem{
    height: 40px;
    background: #f6f6ff !important;
    border-radius: 20px !important;
    margin-top: 10px !important;
    text-align: center !important;
    justify-content: center !important;
}
.usernameLogout{
    text-align: center !important;
    margin-bottom: 10px !important;
}
.textChangePassword{
    color: #5ce706 !important;
    font-size: 12px;
    pointer-events: auto;
    cursor: pointer;
}