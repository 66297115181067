.contImgs{
    display: flex !important;
    width: 100% !important;
    height: 300px !important;
    margin: 20px 0 20px 0 !important;
    border-radius: 10px !important;
}
.imgMainDetail{
    height: 280px;
    width: 100%;
    border-radius: 10px !important;
    margin: auto auto !important;
    object-fit: fill !important;
}
.containerImgMain{
    width: 50% !important;
    display: flex;
    height: 300px !important;
    margin-right: 20px;
    background-color: #fff;
    border-radius: 10px;
    padding: 0 10px 0 10px;
    filter: drop-shadow(0px 0px 2px rgba(0,2,9,.10)) !important;
}
.containerGallery{
    display: flex !important;
    width: 50% !important;
    background-color: #fff;
    border-radius: 10px !important;
    padding: 10px !important;
    height: 280px !important;
    overflow-y: auto !important;
    filter: drop-shadow(0px 0px 2px rgba(0,2,9,.10)) !important;
}
.imageGallery{
    height: 80% !important;
    width: 80% !important;
    border-radius: 10px !important;
    object-fit: fill;
    cursor: pointer !important;
}
.contItemGallery{
    width: 100% !important;
    height: 100% !important;
    border: 3px solid transparent;
    margin: 0px !important;
    padding: 0px !important;
}
.contAvailability{
    display: block !important;
    width: 100% !important;
    justify-content: end !important;
    text-align: end !important;
}
.btnAvailable{
    background-color: #fff !important;
    color: #5f788d !important;
    margin-left: 20px !important; 
    border-radius: 20px !important;
    filter: drop-shadow(0px 0px 2px rgba(0,2,9,.10)) !important;
    height: 35px !important;
    width: 130px !important;
}
.contDetDescription{
    width: 100% !important;
    background-color: #fff !important;
    min-height: 200px !important;
    border-radius: 10px !important;
    filter: drop-shadow(0px 0px 2px rgba(0,2,9,.10)) !important;
    padding: 15px !important;
}
.itemDescriptionCont{
    display: flex;
    width: 100%;
}
.textDesDetail{
    margin: 10px 0 5px 0 !important;
    text-align: justify !important;
    font-size: 16px !important;
}



.mySwiper{
    width: 100%;
    height: 80%;
    margin: auto;
    padding: 10px !important;
}
.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  }
  
  .swiper-slide img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
  }

/* media query */
@media screen and (max-width: 3000px) and (min-width: 1500px){
    .contImgs{
        display: flex !important;
        width: 100% !important;
        height: 450px !important;
        margin: 20px 0 20px 0 !important;
        border-radius: 10px !important;
    }
    .imgMainDetail{
        height: 430px;
        width: 100%;
        border-radius: 10px !important;
        margin: auto auto !important;
    }
    .containerImgMain{
        width: 50% !important;
        display: flex;
        height: 450px !important;
        margin-right: 20px;
        background-color: #fff;
        border-radius: 10px;
        padding: 0 10px 0 10px;
        filter: drop-shadow(0px 0px 2px rgba(0,2,9,.10)) !important;
    }
    .containerGallery{
        display: flex !important;
        width: 50% !important;
        background-color: #fff;
        border-radius: 10px !important;
        padding: 10px !important;
        height: 430px !important;
        overflow-y: auto !important;
        filter: drop-shadow(0px 0px 2px rgba(0,2,9,.10)) !important;
    } 
}

@media screen and (max-width: 720px) and (min-width: 150px){
    .contAvailability{
        display: block !important;
        width: 100% !important;
        justify-content: end !important;
        text-align: end !important;
        margin-top: 10px;
    }
}

@media screen and (max-width: 330px) and (min-width: 150px){
    .btnAvailable{
        background-color: #fff !important;
        color: #5f788d !important;
        margin: 10px 0 0 0 !important; 
        border-radius: 20px !important;
        filter: drop-shadow(0px 0px 2px rgba(0,2,9,.10)) !important;
        height: 35px !important;
        width: 100% !important;
    }
    .contAvailability{
        margin-top: 0px;
    }
}

@media screen and (max-width: 460px) and (min-width: 150px){
    .textDesDetail{
        margin: 10px 0 5px 0 !important;
        text-align: justify !important;
        font-size: 15px !important;
    }
}

@media screen and (max-width: 800px) and (min-width: 150px){
    .contImgs{
        display: block !important;
        width: 100% !important;
        min-height: 610px !important;
        margin: 20px 0 0px 0 !important;
        border-radius: 10px !important;
    }
    .containerImgMain{
        width: 96% !important;
        display: flex;
        height: 320px !important;
        margin: 0 0 20px 0;
        background-color: #fff;
        border-radius: 10px;
        padding: 0 10px 0 10px;
        filter: drop-shadow(0px 0px 2px rgba(0,2,9,.10)) !important;
    }
    .imgMainDetail{
        height: 300px;
        width: 100%;
        border-radius: 10px !important;
        margin: auto auto !important;
    }
    .containerGallery{
        width: 100% !important;
        display: flex !important;
        background-color: #fff;
        border-radius: 10px !important;
        padding: 0px !important;
        height: 250px !important;
        filter: drop-shadow(0px 0px 2px rgba(0,2,9,.10)) !important;
        margin: 0;
    }
    .imageGallery{
        height: 80% !important;
        width: 60% !important;
        border-radius: 10px !important;
        object-fit: fill;
        cursor: pointer !important;
    }
}