.containerChangePassword{
    width: 100%;
    display: flex;
    box-sizing: border-box;
}
.divEmtyChangePassword{
    display: block;
    width: 230px;
}
.divViewChangePassword{
   width: 100%;
   min-height: 89vh;
   box-sizing: border-box !important;
   padding: 15px 15px 0 30px;
   display: flex;
   justify-content: center;
   align-items: center;
}

/* list keep mind */
.containerListKeepMind{
    width: 100%;
    margin: 0 0 30px 0;
}
.contlist{
    margin: 0;
    padding: 0 0px 0px 25px;
}
.titleList{
    margin: 10px 0 !important;
    padding: 0 !important;
    font-size: 15px !important;
    color: #243747 !important;
    font-weight: 500;
}

/* form */
.conatinerTitleChangePassword{
    width: 100%;
    padding: 0 0 10px 0;
}
.containerFormChangePassword{
    width: 80%;
    min-height: 400px;
    background-color: #F0F4F7;
    border-radius: 10px;
    filter: drop-shadow(0px 0px 2px rgba(9,7,9,.10)) !important;
    padding: 20px;
    box-sizing: border-box;
}
.btnChangePassword{
    background-color: #243747 !important;
    color: #fff !important;
    width: 100%;
    border-radius: 20px !important;
}
.btnTestChangePassword{
    background-color: #fff !important;
    width: 100% !important;
    color: #5f788d !important;
    border-radius: 20px !important;
    filter: drop-shadow(0px 0px 2px rgba(9,7,9,.10)) !important;
}
.textAboutTest{
    margin: 0;
    font-size: 15px;
    color: #243747;
    font-weight: 500;
}

@media screen and (max-width:600px) {
    .divEmtyChangePassword{
        display: none;
        width: 0px;
    }
    .divViewChangePassword{
        width: 100%;
        min-height: 89vh;
        box-sizing: border-box !important;
        padding: 15px;
        display: block;
        justify-content: start;
        align-items: start;
     }
    .containerFormChangePassword{
        width: 100%;
        min-height: 400px;
        border-radius: 0px;
        filter: drop-shadow(0px 0px 0px rgba(9,7,9,.10)) !important;
        padding: 0px;
        box-sizing: border-box;
    }
}