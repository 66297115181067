/* .divRootCity{
    background-color: #5f788d;
} */
.containerTwoCity{
    min-height: 150px;
    width: 100%;
    display: flex;
}
.contFormCity{
    width: 100% !important;
    min-height: 160px;
    border-radius: 10px;
}
.titlCompCity{
    font-size: 20px;
    text-transform: uppercase;
    margin: 15px;
}
.contFormSecondCitys{
    text-align: start;
}
