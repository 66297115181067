.containHeaders{
    margin: 10px 0;
}
.subtitleMain{
    font-size: 17px;
    color: #243747;
    margin: 5px 0;
    padding: 0;
}
.textHeaders{
    margin: 0px;
    padding: 0;
}
.textHeaders1{
    margin: 10px 0;
    padding: 0;
}

.subtitleMainH3{
    font-size: 18px;
    color: #243747;
    margin: 5px 0;
    padding: 0;
}
.containTimes{
    margin: 20px 0;
}
.containAdults{
    margin: 10px 0 0 0;
}
.textGeneral{
    margin: 12px 0;
}
.textShort{
    margin: 10px 0;
    color: #5f788d;
    text-decoration: underline;
}
.containTabTerms{
    margin: 20px 0 0 0;
}
.tabContext{
    margin: 0 !important;
    padding: 0;
}
.contTabList{
    display: flex;
    text-align: center !important;
    justify-content: center;
}

@media screen and (max-width: 600px) and (min-width: 150px){
    .itemTab{
        font-size: 12px !important;
    }
}