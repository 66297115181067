.rootNotFound {
  width: 100%; 
  height: 100%;
  background: #F0F4F7;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.divTwoNotFound {
  width: 100%;
}
.titleNotFound {
  color: #5f788d;
  text-transform: uppercase;
}
.imgNotFound{
  width: 800px !important;
  height: 400px !important;
}
.btnNotFound{
  background-color: #243747 !important;
  color: #fff !important;
  width: 150px !important;
  text-transform: uppercase !important;
  border-radius: 20px !important;
}
.not404Found{
  font-size: 80px !important;
  padding: 0 !important;
  margin: 0;
  color: #243747 !important;
}