.paper{
  position: absolute;
  min-width: 240px !important;
  max-width: 300px !important;
  min-height: 100px;
  background: #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.24);
  outline: none;
  border-radius: 10px !important;
  padding: 20px 20px;
  justify-content: space-around;
}
.titleConfirmMessage{
  margin: 0 0 0 10px !important;
  padding: 0;
  font-weight: bolder;
}
.text {
  font-size: 15px;
  font-family:  Roboto;
  color: #545353;
  text-align: center;
  margin: 10px 0 20px 0 !important;
}
.titleAlert {
  font-size: 24px;
  text-align: center;
  font-family: Roboto;
  font-weight: bold;
  color: #545353;
}
.oneBtnConfirm{
  background: #fff !important;
  color: #5f788d !important;
  width: 180px;
  border-radius: 30px !important;
  overflow: visible !important; 
  filter: drop-shadow(0px 0px 1px rgba(0,2,0,.22));
}
.containerBtnConfirm{
  display: flex !important;
  width: 100% !important;
  justify-content: space-around!important;
}
.divBtnConfirmMessage{
  display: flex !important;
  justify-content: center !important;
}
.btnConfirmMessage{
  background: #fff !important;
  color: #5f788d !important; 
  width: 100px !important;
  overflow: visible !important; 
  filter: drop-shadow(0px 0px 1px rgba(0,2,0,.22));
  border-radius: 20px !important;
  margin: auto;
}
.containerIconAlert{
  display: flex !important;
  justify-content: center !important;
  margin: 0 0 20px 0 !important;
}

/* media query */
@media screen and (max-width: 350px) and (min-width: 150px){
  .paper{
    position: absolute;
    min-width: 100px !important;
    max-width: 140px !important;
    min-height: 100px;
    background: #fff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.24);
    outline: none;
    border-radius: 10px !important;
    padding: 20px 20px;
    justify-content: space-around;
    overflow-x: auto;
  }
  .containerBtnConfirm{
    display: block !important;
    width: 100% !important;
  }
  .btnConfirmMessage{
    background: #fff !important;
    color: #5f788d !important; 
    width: 100% !important;
    overflow: visible !important; 
    filter: drop-shadow(0px 0px 1px rgba(0,2,0,.22));
    border-radius: 20px !important;
    margin-bottom: 10px!important;
  }
}