.contNotification{
    padding: 5px 15px;
}
.titleNotification{
    margin: 0 0 10px 0;
    text-transform: uppercase;
    color: #243747;
}

/* card */
.contCardNotification{
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    min-height: 100px;
    margin-top: 10px;
    filter: drop-shadow(0px 0px 1px rgba(1,7,0,.17));
}
.titleCardNotification{
    margin: 0;
    padding: 8px;
    font-size: 15px;
    color: #243747;
}
.divTextNotification{
    padding: 0 8px
}
.textNotification{
    margin: 0;
    font-size: 14px;
    text-align: justify;
}

/* no notifications */
.containerNoNotification{
    height: 100px;
    display: flex;
}
.textNoNotification{
    text-align: center;
    margin: auto auto !important;
    color: #5f788d;
}