.tabContext{
    margin: 0 !important;
    padding: 0;
}
.contTabList{
    display: flex;
    text-align: center !important;
    justify-content: center;
}
.rootSettings{
    display: flex !important;
    width: 100% !important;
    height: 93%;
}
.contEmptySettings{
    max-width: 270px !important;
    min-width: 230px;
}
.containerSettings{
    display: flex !important;
    width: 100% !important;
}
.divTabs{
    margin: auto auto !important;
    padding: 10px 20px 20px 20px;
    min-width: 320px !important;
    border-radius: 10px;
    width: 650px;
    min-height: 200px;
    justify-content: center !important;
    text-align: center !important;
    overflow: visible;
    filter: drop-shadow(0px 0px 2px rgba(1, 1, 2, 0.2)) !important;
    background-color: #F0F4F7;
}
.textTabs{
    color: #243747 !important;
    text-decoration-color: #243747;
    
}

/* style assign role */
.contCheckAssign{
    display: flex;
    width: 100%;
}
.contAssign{
    width: 50%;
    display: flex;
    margin-left: 10px;
}
.contAssignText{
    margin: auto 0!important;
}

.contUpdateEmployees{
    margin: 50px !important;
}
.btnUpdateEmployees{
    background-color: #243747 !important;
    color: #fff !important;
    border-radius: 20px !important;
}

/* media query */
@media screen and (max-width: 600px) and (min-width: 150px){
    .contEmptySettings{
        max-width: 0px !important;
        min-width: 0px;
    }
    .divTabs{
        margin: auto auto !important;
        padding: 10px 20px 20px 20px;
        min-width: 220px !important;
        border-radius: 10px;
        width: 100%;
        min-height: 200px;
        justify-content: center !important;
        text-align: center !important;
        overflow: visible;
        filter: drop-shadow(0px 0px 2px rgba(1, 1, 2, 0.2)) !important;
    }
    .itemTab{
        font-size: 12px !important;
    }
}

@media screen and (max-width: 950px) and (min-width: 601px){
    .rootSettings{
        display: flex !important;
        width: 100% !important;
        height: 93%;
    }
    .divTabs{
        margin: auto auto !important;
        padding: 10px 20px 20px 20px;
        min-width: 320px !important;
        border-radius: 10px;
        width: 100%;
        min-height: 200px;
        justify-content: center !important;
        text-align: center !important;
        overflow: visible;
        filter: drop-shadow(0px 0px 2px rgba(1, 1, 2, 0.2)) !important;
    }
}

@media screen and (max-width: 300px) and (min-width: 150px){
    .contCheckAssign{
        display: block;
        width: 100%;
    }
    .contAssign{
        width: 100%;
        display: flex;
        margin-left: 10px;
    }
}

@media screen and (max-width: 370px) and (min-width: 150px){
    .contUpdateEmployees{
        margin: 30px 0px !important;
    }
    .btnUpdateEmployees{
        background-color: #243747 !important;
        color: #fff !important;
        border-radius: 20px !important;
        width: 100% !important;
    }
}