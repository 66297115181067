.root{
    background-color: #fff;
    width: 87% !important;
    height: 280px !important;
    display: block !important;
    border-radius: 10px;
    margin: 0 20px 20px 0 !important;
    padding: 15px !important;
    overflow: visible;
    filter: drop-shadow(0px 0px 2px rgba(9,9,9,.23)) !important;
}
.contImg{
    width: 100%;
    height: 180px;
    display: block !important;
}
.contName{
    display: flex !important;
    width: 100%;
    justify-content: space-between;
    text-align: start;
}
.img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    /* object-fit: cover; */

}
.nameLocation{
    margin: 10px 0 20px 0;
    padding: 0;
    font-size: 18px;
    color: #243747;
}
.btnCardReserve{
    background-color: #fff !important;
    color: #5f788d !important;
    width: 140px;
    border-radius: 20px !important;
    overflow: visible;
    filter: drop-shadow(0px 0px 2px rgba(9,9,9,.23)) !important;
}
.contBtn{
    display: flex;
    justify-content: space-between !important;
}
.containerIconLocation{
    display: flex;
}
.iconBtnCardLocation{
    margin: auto 0 auto 15px !important;
    height: 30px;
}
.iconBtnCardLocation:hover{
    background-color: #fff !important;
}
.iconCardLocation{
    color: #5f788d;
}
.iconCardLocation:hover{
    color: #5ce706;
}

/* media query */

@media screen and (max-width: 3000px) and (min-width: 1700px){   
    .root{
        background-color: #fff;
        width: 87% !important;
        height: 350px !important;
        display: block !important;
        border-radius: 10px;
        margin: 0 20px 20px 0 !important;
        padding: 15px !important;
        overflow: visible;
        filter: drop-shadow(0px 0px 2px rgba(9,9,9,.23)) !important;
    }
    .contImg{
        width: 100%;
        height: 250px;
        display: block !important;
    }
}

@media screen and (max-width: 1699px) and (min-width: 1500px){   
    .root{
        background-color: #fff;
        width: 87% !important;
        height: 320px !important;
        display: block !important;
        border-radius: 10px;
        margin: 0 20px 20px 0 !important;
        padding: 15px !important;
        overflow: visible;
        filter: drop-shadow(0px 0px 2px rgba(9,9,9,.23)) !important;
    }
    .contImg{
        width: 100%;
        height: 220px;
        display: block !important;
    }
}

@media screen and (max-width: 900px) and (min-width: 401px){   
    .img{
        width: 75%;
        height: 180px;
        border-radius: 10px;
    }
}

@media screen and (max-width: 400px) and (min-width: 150px){
    .root{
        display: block !important;
        min-height: 330px !important;
        margin: 0 20px 20px 0 !important;
        padding: 15px !important;
    }
    .img{
        width: 100%;
        border-radius: 10px;
    }
    .nameLocation{
        margin: 10px 0 20px 0;
        padding: 0;
        font-size: 17px;
        color: #243747;
    }
    .iconBtnCardLocation{
        margin: auto 0 auto 12px !important;
        height: 20px !important;
    }
    .iconCardLocation{
        color: #5f788d;
        font-size: 17px !important;
    }
    .contBtn{
        display: block !important;
    }
    .btnCardReserve{
        display: block !important;
        background-color: #fff !important;
        color: #5f788d !important;
        width: 100% !important;
        border-radius: 20px !important;
        overflow: visible;
        filter: drop-shadow(0px 0px 2px rgba(9,9,9,.23)) !important;
        margin: 10px 0px !important;
    }
}