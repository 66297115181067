
.addImage{
    background-color: #fff;
    min-height: 200px !important;
    max-height: 300px !important;
    border-radius: 10px;
    margin: 0 0 10px 0 !important;
    padding: 15px !important;
    overflow-y: auto;
    filter: drop-shadow(0px 0px 2px rgba(9,9,9,.23)) !important;
    border: 1px dashed #a0aec0;
    cursor: pointer;
    text-align: center !important;
    display: grid;
    place-items: center;
}
.imageAddLocation{
    width: 100%;
    height: 200px;
    border-radius: 10px !important;
    object-fit: fill;
}

.image-container{
    width: 100%;
}

.imageAddLocationGallery {
    width: 100%;
    height: 6rem;
    object-fit: cover; 
    border-radius: 10px !important;
}

.btSaveAddLocation{
    background: #243747 !important;
    width: 110px !important;
    color: #fff !important;
    margin-right: 15px !important;
    overflow: visible !important; 
    filter: drop-shadow(0px 0px 1px rgba(9,7,9,.10)) !important;
    border-radius: 30px !important;
    text-align: center !important;
}
.btCancelAddLocation{
    background: #fff !important;
    width: 110px !important;
    color: #243747 !important;
    overflow: visible !important; 
    filter: drop-shadow(0px 0px 1px rgba(9,7,9,.10)) !important;
    border-radius: 30px !important;
}

/* form */
.divbtnsAddLocation{
    display: flex !important;
    justify-content: end !important;
    text-align: center !important;
    margin: 0 !important;
    padding: 0 !important;
}
.linkAddFeature{
    display: flex;
    margin: 0px 0px 15px 10px;
}
.titleAmenities{
    font-size: 15px !important;
    color: #243747 !important;
    text-transform: uppercase;
    margin-right: 50px !important;
}

/* update gallery */

.iconAddImage{
    position: absolute !important;
    top: 1rem;
    right: 1.5rem
}

/* media query */
@media screen and (max-width: 599px) and (min-width: 150px){
    .divbtnsAddLocation{
        display: flex !important;
        justify-content: end !important;
        text-align: center !important;
        margin: 15px 0px 0px 0px !important;
        padding: 0 !important;
    }
}

@media screen and (max-width: 290px) and (min-width: 150px){
    .divbtnsAddLocation{
        display: block !important;
        justify-content: center !important;
        text-align: center !important;
        margin: 15px 0 0 0 !important;
        padding: 0 !important;
    }
    .btSaveAddLocation{
        background: #243747 !important;
        width: 100% !important;
        color: #fff !important;
        margin-right: 0px !important;
        filter: drop-shadow(0px 0px 1px rgba(9,7,9,.10)) !important;
        border-radius: 30px !important;
        text-align: center !important;
    }
    .btCancelAddLocation{
        background: #fff !important;
        width: 100% !important;
        color: #243747 !important; 
        filter: drop-shadow(0px 0px 1px rgba(9,7,9,.10)) !important;
        border-radius: 30px !important;
        margin-top: 15px !important;
    }
}

@media screen and (max-width: 500px) and (min-width: 150px){
    .iconAddImage{
        position: relative !important;
        top: 0rem;
        right: 0rem
    }
}

@media screen and (max-width: 400px) and (min-width: 150px){
    .linkAddFeature{
        display: block;
        margin: 10px 0px 15px 10px;
    }
    .titleAmenities{
        font-size: 15px !important;
        color: #243747 !important;
        text-transform: uppercase;
        margin: 0px 0 10px 0 !important;
    }
}

@media screen and (max-width: 900px) and (min-width: 401px){
    .linkAddFeature{
        display: flex;
        margin: 15px 0px 15px 10px;
    }
    .titleAmenities{
        font-size: 15px !important;
        color: #243747 !important;
        text-transform: uppercase;
        margin: 0px 50px 0px 0 !important;
    }
}
